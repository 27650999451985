

































































































































































































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import NorthStarService from "@/NorthStarService";
import {NorthStar, Objective as ObjectiveObject, Objective} from "@/typescript/types";
import CreateObjectiveTrigger from "@/components/objectives/CreateObjectiveTrigger.vue";
import { endOfWeek } from "date-fns";
import gql from "graphql-tag";
import {DeleteObjectiveMutation, ObjectiveDetailedFragment} from "@/graphql/objective";
import VerifyUser from "@/components/auth/VerifyUser.vue";
import ConfettiCanon from "@/components/general/ConfettiCanon.vue";
import { WorkspaceQuery } from '@/graphql/workspace';
import { addDays } from 'date-fns';

@Component({
  components: {ConfettiCanon, CreateObjectiveTrigger},
})
export default class InRoomActivation extends Vue {

  @Prop({default: false, required: false})
  fromExpansion!: boolean

  createNorthStarInput: string | null = null;
  createActivityInput: string | null = null;
  submitted: boolean = false;
  northstarsAddedCount: number = 0;
  service: NorthStarService | null = null;
  addedNorthStars: NorthStar[] = [];
  addedObjectives: Objective[] = [];
  addActivitiesActive: boolean = false;
  lastAddedNorthstar: NorthStar | null = null;
  assignedPriority: string = 'normal';
  introductionActive: boolean = false;
  addNorthstarManuallyActive: boolean = false;

  selectedGoalSuggestion: any | null = null;
  highlightButton: boolean = false;
  suggestionWait: boolean = false;
  delayedComplete: boolean = false;

  // True will popup claim account after activation
  showClaimAfterActivation: boolean = false;

  allowSwitchStatus: boolean = true;

  personalGoalSuggestions: Array<{ name: string, items: Array<any> }> = [
    {
      name: 'Get more productive',
      items: [
        'Declutter my desk',
        'Quick inbox cleanup',
        'Review my calendar',
      ]},
    {
      name: 'Become more organized',
      items: [
        'Declutter my desk',
        'Cancel subscriptions',
        'Quick inbox cleanup',
        'Set a weekly budget',
      ]},
    {
      name: 'Improve time management',
      items: [
        'Create a daily schedule',
        'Use time-blocking technique',
        'Prioritize tasks effectively',
      ]}
  ];

  collaborativeGoalSuggestions: Array<{ name: string, items: Array<any> }> = [
    {
      name: 'Increase team engagement',
      items: [
        'Schedule an offsite',
        'Plan your team goals',
        'Check-in on a colleague',
      ]},
    {
      name: 'Collaborate efficiently',
      items: [
        'Invite a colleague to pembio',
        'Plan a week in review meeting',
        'Set up clear team goals',
      ]},
  ];

  colors: Array<string> = [
    '#754373',
    '#4a677d',
    '#7b7343',
  ];

  get me() {
    return this.$store.state.me;
  }

  get onboardingUseCase() {
    return this.$store.state.onboarding_use_case;
  }

  get suggestedGoalsForUsertype() {
    if(this.onboardingUseCase === 'personal') {
      return this.personalGoalSuggestions;
    } else if(this.onboardingUseCase === 'collaborative') {
      return this.collaborativeGoalSuggestions;
    } else {
      return this.personalGoalSuggestions;
    }
  }

  get userType() {
    return this.$store.state.me.account_type;
  }

  get addedNorthstarsCount() {
    return this.addedNorthStars.length;
  }

  get recommendedNsCountLeft() {
    return 3 - this.addedNorthstarsCount;
  }

  get addedActivitiesCount() {
    return this.addedObjectives.length;
  }

  get recommendedActivitiesCountLeft() {
    return 3 - this.addedActivitiesCount;
  }

  get isOnGuestAccount() {
    return this.$store.state.me.isFake;
  }

  get isFromAd() {
    return this.$store.state.user_from_ad
  }

  get endDate() {
    return endOfWeek(new Date(), {weekStartsOn: 1});
  }

 

  highlightCorrectActivityButton() {
    this.highlightButton = true;
    setTimeout(() => this.highlightButton = false, 1000);
    this.$nextTick(function () {
      // @ts-ignore
      this.$refs.createActivityInput.focus();
    });
  }

  focusOnManuallyAddNorthstar() {
    this.addNorthstarManuallyActive = true;
    this.$nextTick(function () {
      // @ts-ignore
      this.$refs.createNsInput.focus();
    });
  }

  completeIntroduction() {
    this.introductionActive = false;
    this.$gtag.event('pmb_new_activation_introduction_completed');
  }

  skipActivation() {
    this.$gtag.event('pmb_new_app_activation_skipped_manually');
    this.completeActivation();
  }

  successCompleteActivation() {
    this.$gtag.event('pmb_new_app_activation_completed_success');
    this.delayedComplete = true;
    setTimeout(() => {
      this.completeActivation();
    }, 
    );
  }

  completeActivation() {
    this.$store.commit('set_currently_onboarding', false);
    this.$store.commit('set_onboarding_completed', true);
    this.$store.commit('set_currently_onboarding_in_room', false);
    this.$store.commit('set_onboarding_activities_added', false);
    this.$store.commit('set_currently_in_activation_step', false);
    this.$store.commit('set_template_onboarding', true);
    this.$store.commit('set_dnd_onboarding', true);
 

   
    this.$store.commit('show', false);
    this.$router.replace({
      name: 'room',
      params: {
        board_pid: this.$store.state.board.pid,
        workspace_pid: this.$store.state.active_workspace_pid,
      }
    });
    //@ts-ignore
    this.$parent.close();
    if(this.isOnGuestAccount && this.showClaimAfterActivation) {
      setTimeout(() => {
        this.claimAccount();
      }, 1200);
    } else {
      this.$nextTick(function () {
        //@ts-ignore ToDo: fix type issue
        // location.reload();
        this.$store.commit('set_user_onboarding_number', 1);
      });
    }
  }



  mounted() {
    this.$store.commit('set_user_onboarding_number', 1);
    this.$gtag.event('pmb_new_app_activation_mounted');
    this.service = new NorthStarService(this.$apollo);
  }

  get suggestColorFromNsCount() {
    let count = this.addedNorthStars.length;
    if(count === 0) {
      return '#773f75';
    } else if(count === 1) {
      return '#4a677d';
    } else if(count === 2) {
      return '#7b7343';
    } else {
      return null;
    }
  }

  addFromSuggestion(name: string, category: any) {
    if(this.suggestionWait) {
      return
    }
    this.suggestionWait = true;
    setTimeout(() => this.suggestionWait = false, 500);
    this.selectedGoalSuggestion = category.items;
    this.createNorthStarInput = name;
    this.$gtag.event('pmb_new_app_activation_add_goal_from_suggestion');
    this.createOrAddNorthStar(false);
  }

  addActivityFromSuggestion(activity: any, index: number) {
    if(this.suggestionWait) {
      return
    }
    this.allowSwitchStatus = false;
    this.suggestionWait = true;
    setTimeout(() => this.suggestionWait = false, 500);
    this.$gtag.event('pmb_new_app_activation_add_activity_from_suggestion');
    this.createActivityInput = activity;
    this.createObjectiveMutation(false);
    setTimeout(() => this.removeGoalSuggestion(index), 500);
  }

  handleAddActivity(manually: boolean) {
    if ( this.allowSwitchStatus) {
      this.allowSwitchStatus = false;
      this.createObjectiveMutation(manually);
      
      setTimeout(() => {
        this.allowSwitchStatus = true;
      }, 900);
    }
  }


  removeGoalSuggestion(index: number): void {
    if (index >= 0 && index < this.selectedGoalSuggestion.length) {
      this.selectedGoalSuggestion.splice(index, 1);
      
    }
  }

  claimAccount() {
    this.$gtag.event('pmb_user_claim_account_manually_initiated_sidebar');
    this.$buefy.modal.open({
      component: VerifyUser,
      props: {
        claimWs: (this.me.isFake) ?? false,
        clickedClaim: true,
        canBypass: true
      },
      width: '500px',
      canCancel: false,
      parent: this,
    });
  }

  createObjectiveMutation(manually: boolean | null) {
    if(manually) {
      this.$gtag.event('pmb_new_app_activation_objective_added_manually');
      this.$gtag.event('pembio_create_objective');
    }
    this.$gtag.event('pmb_new_app_activation_objective_added');
    return this.$apollo
      .mutate({
        mutation: gql`
                    mutation createObjective(
                        $owner_id: ID!
                        $name: String!
                        $board_id: ID!
                        $start_date: DateTime
                        $due_date: DateTime
                        $priority: ObjectivePriority
                        $background_color: String
                    ) {
                        createObjective(
                            input: {
                                owner: { connect: $owner_id }
                                board: { connect: $board_id }
                                name: $name
                                start_date: $start_date
                                due_date: $due_date
                                priority: $priority
                                background_color: $background_color
                            }
                        ) {
                            ...ObjectiveDetailed
                        }
                    }
                    ${ObjectiveDetailedFragment}
                `,
        variables: {
          owner_id: this.$store.state.me.id,
          name: this.createActivityInput,
          board_id: this.$store.state.board.id,
          start_date: new Date(),
          end_date: null,
          due_date: this.endDate,
          priority: this.assignedPriority,
        },
        refetchQueries: [{query: WorkspaceQuery, variables: { pid: this.$store.state.workspace.pid }}]
      })
      .then((result) => {
        if(this.lastAddedNorthstar){
          this.attachNorthStar(result.data.createObjective, this.lastAddedNorthstar);
          result.data.createObjective['from_northstar'] = true;
        }else{
          this.$events.fire('objective-created', result.data.createObjective);
        }

        this.addedObjectives.push(result.data.createObjective);
        this.$emit('submitted', true);
        this.$emit('objective-created-from-sprint', result.data.createObjective);
      })
      .finally(() => {
        this.createActivityInput = null;
        this.allowSwitchStatus = true;
      });
  }

  deleteObjective(id: string, index: number) {
    this.addedObjectives.splice(index, 1);
  
    this.$apollo.mutate({
      mutation: DeleteObjectiveMutation,
      variables: {
        id: id,
      },
    }).then((res: any) => {
      this.$events.fire('objective-deleted', res.data.deleteObjective);
    })
  }

  attachNorthStar(objective: ObjectiveObject, northStar: NorthStar){
    this.service?.addNorthStarToObjective(objective, [northStar]).then(res => {
      this.$events.fire('objective-created-from-northstar', {objective: res.data.updateObjective, northStarId: northStar.id });
      return;
    });
  }

  jumpToActivitiesStep() {
    this.addActivitiesActive = true;
    this.$gtag.event('pmb_new_app_activation_jump_to_activities');
    this.$nextTick(function () {
      // @ts-ignore
      this.$refs.createActivityInput.focus();
    });
  }

  createOrAddNorthStar(manually: boolean | null): void {
    if(manually) {
      this.$gtag.event('pmb_new_app_activation_northstar_added_manually');
    }
    this.$gtag.event('pmb_new_app_activation_northstar_added');
    this.submitted = true;
  
    // Get the current date
    const currentDate = new Date();
  
    // Set start date to the first day of the current month
    const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  
    // Set due date to 40 days after the start date
    const dueDate = addDays(startDate, 40);

    let finalColor = this.suggestColorFromNsCount;
    if (this.$store.state.board) {
    this.service?.createNorthStar(this.$store.state.workspace, this.$store.state.board, this.createNorthStarInput!, dueDate, startDate, finalColor).then((result: any) => {
      this.$store.commit('set_newly_created_northstar_count', (this.$store.state.newlyCreatedNorthstarsCountInWorkspace == null) ? 1 : this.$store.state.newlyCreatedNorthstarsCountInWorkspace + 1)
      this.$store.commit('set_newly_created_northstar_in_board', result.data.createNorthStar.id);
      this.$gtag.event('pembio_activation_create_north_star');
      this.$events.fire('new_northstar_created', result.data.createNorthStar)
      
      this.lastAddedNorthstar = result.data.createNorthStar;
      this.addedNorthStars.push(result.data.createNorthStar);
     
      if(this.addedNorthstarsCount >= 1) {
        this.jumpToActivitiesStep();
      }
    })
    }
    this.$nextTick(function () {
    // @ts-ignore
      this.$refs.createNsInput.focus();
    });
    this.createNorthStarInput = null;
    this.$emit('northstar-added', true);
  }
}
