



























































































import {Component, Vue} from 'vue-property-decorator';
import {PublicBoardTemplatePaginator} from "@/typescript/types";
import NorthStarService from "@/NorthStarService";
import {PublicBoardTemplates} from "@/graphql/boardTemplate";

@Component({
  components: {},
  apollo: {
    PublicBoardTemplates: {
      query: PublicBoardTemplates,
      variables() {
        return {
          page: this.publicBoardTemplatesPageCount
        }

      },
      result(result) {
       
        if (result && result.data && result.data.PublicBoardTemplates && this.PublicBoardTemplatesData && this.PublicBoardTemplatesData.data.length > 0) {
          this.PublicBoardTemplatesData.data  = this.filterDuplicateData([...this.PublicBoardTemplatesData.data, ...result.data.PublicBoardTemplates.data]);
          this.PublicBoardTemplatesData.paginatorInfo = result.data.PublicBoardTemplates.paginatorInfo;

        }else{
          this.PublicBoardTemplatesData = result.data.PublicBoardTemplates;
        }
      },
    },
  }
})
export default class StickyTemplateWindow extends Vue {

  PublicBoardTemplatesData: PublicBoardTemplatePaginator | null = null;
  publicBoardTemplatesPageCount: number = 1;
  showTemplateDescriptions: boolean = false;
  selectedSuggestions: Array<string> = [];
  service: NorthStarService | null = null;
  addedBoard: string | null = null;
  
  get onboardingStep() {
    return this.$store.state.user_onboarding_number;
  }

  get workspace() {
    return this.$store.state.workspace;
  }

  publicBoardTemplatesLoadMore() {
    this.publicBoardTemplatesPageCount++;
    this.$apollo.queries.PublicBoardTemplates.refetch();
  }

  ifAlreadySelected(northStarId: string) {
    return this.selectedSuggestions.includes(northStarId);
  }

  copyNorthstar(northstar_id: string, event: MouseEvent) {
    event.stopPropagation();  // Stop the click event from propagating
    event.preventDefault();   // Prevent the default action

    this.$gtag.event('pmb_create_ns_from_templates');
    this.$emit('close-window');
    this.$emit('close-input');
    // this.$store.commit('set_user_onboarding_step', 6);
    // this.$gtag.event('user_reached_onboarding_step_6');


    if (this.ifAlreadySelected(northstar_id)) {
      return;
    }
    if (this.$store.state.board) {
      this.addedBoard = this.$store.state.board.id;
    }
    if (this.selectedSuggestions.length == 0 || this.addedBoard != null) {
      this.selectedSuggestions.push(northstar_id);
      this.service?.copyNorthStar(northstar_id, this.addedBoard, this.workspace.id).then((res: any) => {
        if (res.data.copyNorthStar && res.data.copyNorthStar.boards.length > 0) {
          this.addedBoard = res.data.copyNorthStar.boards[0].id;
          this.$store.commit('set_newly_created_northstar_count', (this.$store.state.newlyCreatedNorthstarsCountInWorkspace == null) ? 1 : this.$store.state.newlyCreatedNorthstarsCountInWorkspace + 1);
          this.$events.fire('northstars-updated-my-northstar', res.data.copyNorthStar);
          // this.$buefy.toast.open({
          //   message: 'Goal added successfully',
          //   position: 'is-bottom-right',
          //   type: 'is-black',
          // });
        }
        this.$store.commit('set_newly_created_northstar_in_board', res.data.copyNorthStar.id);
      });
    }
  }


  mounted() {
    this.$gtag.event('pmb_app_create_northstar_modal_mounted');
    this.service = new NorthStarService(this.$apollo);

    
    
  }
}
