





import {Component, Vue} from 'vue-property-decorator';
import NorthStarService from "../../../NorthStarService"
import {NorthStar, Objective as ObjectiveObject, Objective} from "@/typescript/types";
import {ObjectiveDetailedFragment} from "@/graphql/objective";
import {
  // endOfMonth,
 
  // startOfMonth,
  endOfWeek
} from "date-fns";
import gql from "graphql-tag";

@Component({
  components: {},
})
export default class CreateExampleData extends Vue {

  service: NorthStarService | null = null;
  start_date = new Date();
  due_date = new Date();
  lastAddedNorthstar: NorthStar | null = null;
  addedNorthStars: NorthStar[] = [];

  suggestionWait: boolean = false;
  selectedGoalSuggestion: any | null = null;
  createNorthStarInput: string | null = null;
  submitted: boolean = false;
  addedObjectives: Objective[] = [];
  createActivityInput: string | null = null;
  allowSwitchStatus: boolean = true;
  assignedPriority: string = 'normal';

  goals = [
    {
      name: 'Meetings',
      items: []  
    },
    {
      name: 'Upcoming',
      items: []  
    }
  ];

  // goals = [
  //   {
  //     name: 'Getting started\u00A0🚀',
  //     items: [
  //       'Add your first goal',
  //       'Invite a team member',
  //       'Complete an activity',
  //       'Build a new room',
            
  //     ]
  //   }
  // ];

  get userType() {
    return this.$store.state.me.account_type;
  }

  get boards() {
    return this.$store.state.workspace.boards;
  }

  get workspace() {
    return this.$store.state.workspace;
  }

  get me() {
    return this.$store.state.me;
  }

  get endDate() {
    return endOfWeek(new Date(), {weekStartsOn: 1});
  }

  get onboardingStep() {
    return this.$store.state.user_onboarding_number;
  }

  get suggestColorFromNsCount() {
    let count = this.addedNorthStars.length;
    if(count === 0) {
      return '#5B507A';
    } else if(count === 1) {
      return '#4a677d';
    } else if(count === 2) {
      return '#7b7343';
    } else {
      return null;
    }
  }

  async createOrAddNorthStar(northstarName: string) {
    this.$gtag.event('pmb_new_app_activation_northstar_added');
    this.submitted = true;
    const grayColor = '#808080';  // Gray color
    if (this.$store.state.board) {
      await this.service?.createNorthStar(this.$store.state.workspace, this.$store.state.board, northstarName, null, null, grayColor).then(async (result: any) => {
        this.$events.fire('new-northstar-id-created', result.data.createNorthStar.id);
        this.$store.commit('set_newly_created_northstar_count', (this.$store.state.newlyCreatedNorthstarsCountInWorkspace == null) ? 1 : this.$store.state.newlyCreatedNorthstarsCountInWorkspace + 1);
        this.$store.commit('set_newly_created_northstar_in_board', result.data.createNorthStar.id);
        this.$gtag.event('pembio_activation_create_north_star');
        this.$events.fire('new_northstar_created', result.data.createNorthStar);
        this.lastAddedNorthstar = result.data.createNorthStar;
        this.addedNorthStars.push(result.data.createNorthStar);
          
        // Add a 2-second delay before adding activities
        await new Promise(resolve => setTimeout(resolve, 500));
          
        // Pass activities without dates
        // for (let i = 0; i < activities.length; i++) {
        //   await this.createObjectiveMutation(activities[i], null, null);
        // }
      });
    }
  
    this.$emit('northstar-added', true);
  }




  async createObjectiveMutation(activity: string, startDate: Date, dueDate: Date) {
    this.$gtag.event('pmb_new_app_activation_objective_added');
    return this.$apollo
      .mutate({
        mutation: gql`
                  mutation createObjective(
                      $owner_id: ID!
                      $name: String!
                      $board_id: ID!
                      $start_date: DateTime
                      $due_date: DateTime
                      $priority: ObjectivePriority
                      $background_color: String
                  ) {
                      createObjective(
                          input: {
                              owner: { connect: $owner_id }
                              board: { connect: $board_id }
                              name: $name
                              start_date: $start_date
                              due_date: $due_date
                              priority: $priority
                              background_color: $background_color
                          }
                      ) {
                          ...ObjectiveDetailed
                      }
                  }
                  ${ObjectiveDetailedFragment}
              `,
        variables: {
          owner_id: this.$store.state.me.id,
          name: activity,
          board_id: this.$store.state.board.id,
          start_date: startDate,  
          due_date: dueDate,      
          priority: this.assignedPriority,
        }
      })
      .then((result) => {
        if(this.lastAddedNorthstar){
          this.attachNorthStar(result.data.createObjective, this.lastAddedNorthstar);
          result.data.createObjective['from_northstar'] = true;
        }else{
          this.$events.fire('objective-created', result.data.createObjective);
          
        }

        this.addedObjectives.push(result.data.createObjective);
        this.$emit('submitted', true);
        this.$emit('objective-created-from-sprint', result.data.createObjective);
      })
      .finally(() => {
        this.createActivityInput = null;
        this.allowSwitchStatus = true;
      });
  }


  attachNorthStar(objective: ObjectiveObject, northStar: NorthStar){
    this.service?.addNorthStarToObjective(objective, [northStar]).then(res => {
      this.$events.fire('objective-created-from-northstar', {objective: res.data.updateObjective, northStarId: northStar.id });
  
      return;
    });
  }

  // async ononboardingStepChange() {
  // // Create NorthStars without items
  //   for (const goal of this.goals) {
  //     await this.createOrAddNorthStar(goal.name, goal.items);
  //   }

  //   setTimeout(() => {
  //     this.$store.commit('set_user_onboarding_step', 2);
  //   }, 900);

  //   await new Promise(resolve => setTimeout(resolve, 1800));
  //   this.$events.fire('pulse_first_ns_input');
  //   this.$events.fire('trigger_add_northstar');
  //   this.$store.commit('set_user_onboarding_step', null);
  // }



  mounted() {
    
    this.service = new NorthStarService(this.$apollo);

    this.due_date = this.endDate;

  

    // if(this.userType === 'work') {
    //   this.goals = [
    //     {
    //       name: 'Getting started in Pembio 🚀',
    //       items: [
    //         'Add your first goal',
    //         'Invite a team member',
    //         'Add a Todo',
            
    //       ]
    //     },
    //     {
    //       name: 'Increase team engagement',
    //       items: [
    //         'Schedule an offsite',
    //         'Plan your team goals',
    //         'Check-in on a colleague',
    //       ]
    //     },
    //     {
    //       name: 'Optimize work processes',
    //       items: [
    //         'Identify bottlenecks',
    //         'Streamline communication',
    //         'Implement productivity tools',
    //       ]
    //     }
    //   ];
    // } else if(this.userType === 'school') {
    //   this.goals = [
    //     {
    //       name: 'Prepare for test',
    //       items: [
    //         'Study for test',
    //         'Prepare test material',
    //         'Review test material',
    //       ]
    //     },
    //     {
    //       name: 'Start study group',
    //       items: [
    //         'Invite classmates',
    //         'Set study schedule',
    //         'Find location',
    //       ]
    //     },
    //     {
    //       name: 'Organize school projects', 
    //       items: [
    //         'Divide tasks among team',
    //         'Set deadlines for each task',
    //         'Collect and review final work',
    //       ]
    //     }
    //   ];
    // } else if(this.userType === 'personal') {
    //   this.goals = [
    //     {
    //       name: 'Get more productive',
    //       items: [
    //         'Declutter my desk',
    //         'Quick inbox cleanup',
    //         'Review my calendar',
    //       ]
    //     },
    //     {
    //       name: 'Become more organized',
    //       items: [
    //         'Create a to-do list',
    //         'Set up a filing system',
    //         'Organize your workspace',
    //       ]
    //     },
    //     {
    //       name: 'Enhance personal growth', 
    //       items: [
    //         'Read a book on self-improvement',
    //         'Start a new hobby',
    //         'Set personal development goals',
    //       ]
    //     }
    //   ];
    // }

    // this.ononboardingStepChange();
  }


}
