






















































import {Component, Vue, Watch} from 'vue-property-decorator';
import {BoardQuery, UpdateLastSeenMessageMutation} from "@/graphql/board";
import {Board as BoardObject, Maybe} from "@/typescript/types";
import PusherService from "@/PusherService";
import UserAvatar from "@/components/user/UserAvatar.vue";
import BoardMembers from "@/components/board/BoardMembers.vue";
import WorkspacePage from "@/components/layout/WorkspacePage.vue";
import BoardSettings from "@/components/board/utilities/BoardSettings.vue";
import Board from "@/components/board/Board.vue";
import MasterDashboard from "@/components/workspace/MasterDashboard.vue";
import BoardSettingsDropdown from "@/components/board/utilities/BoardSettingsDropdown.vue";

@Component({
  components: {BoardSettingsDropdown, Board, BoardSettings, WorkspacePage, UserAvatar, MasterDashboard},
  apollo: {
    board: {
      query: BoardQuery,
      variables() {
        return {
          pid: this.$route.params.board_pid,
        };
      },
      update(result) {
        if (result.board) {
          this.$store.commit('set_board', result.board);
          this.$store.commit('set_active_board_pid', result.board.pid);
          this.boardLoaded = true;
          let memberData = result.board.members.filter((member: any) => {
            return member.user.id === this.me.id;
          });
          this.$store.commit('set_last_seen_message', memberData.last_seen_board_message_id);
          return result.board;
        }
      },
      error(error) {
        if(error.graphQLErrors[0].message) {
          this.$buefy.toast.open({
            message: error.graphQLErrors[0].message,
            position: 'is-bottom-right',
            type: 'is-black',
          });

          this.$router.go(-1);
        }
      }
    },
  }
})
export default class Room extends Vue {
  board!: BoardObject;
  currentLoadedBoardId!: Maybe<string>;
  currentLoadedBoardPid!: Maybe<string>;
  isMember: boolean = true;
  chatExpanded: boolean = false;
  chatMessageLoaded: boolean = false;
  boardLoaded: boolean = false;
  objectiveLoaded: boolean = false;
  open: boolean = false;

  get me() {
    return this.$store.state.me;
  }

  get workspace() {
    return this.$store.state.workspace;
  }

  get currentlyOnboarding() {
    return this.$store.state.currently_onboarding_in_room;
  }


  get finishedLoading() {
    if(this.$route.name === 'introduction' || this.$route.name === 'collaboration') {
      return false;
    }else if(this.boardLoaded && this.chatMessageLoaded && this.objectiveLoaded) {

      return false;
    }
    return true;
  }

 

  getBackgroundLink(url: string) {
    //@ts-ignore
    return this.$attachmentsLinks.file_path['background'] + url;
  }

  checkIfUserBlockedFromWorkspace(userId: string) {
    let member = this.workspace.allMembers.filter((member: any) => {
      return member.user.id === userId;
    });


    if(member[0].access_workspace == 0) {
      return false;
    }else{
      return true;
    }
  }

  routeBackToSettings() {
    if(this.$route.name === 'room') {
      this.$router.push({name: 'board-settings' })
    } else {
      this.$router.push({name: 'room' });
    }
  }

  isViewing(userId: string){
    return PusherService.isViewingBoard(userId, this.board.pid);
  }

  playSound() {
    if(this.$store.state.mute_notification_sound) {
      let audio = new Audio(require('@/assets/audio/join_room.mp3'));
      audio.volume = 0.2;
      audio.play();
    }

  }

  openDateSelector() {
    if(this.$route.name !== 'room') {
      this.$router.push({ name: 'room', params: { board_pid: this.$route.params.board_pid} });
    } else {
      this.$router.push({ name: 'board-settings', params: { board_pid: this.$route.params.board_pid} });
    }
  }

  hasStatus(status: any) {
    if (status !== null) {
      return status.emoji;
    } else {
      return null;
    }
  }

  toggleChatExpanded() {
    this.chatExpanded = !this.chatExpanded;
  }

  /*
  getBoardMilestoneLength(milestone: any){
    let milestones = milestone.filter((milestoneData: any)=>{
      if(milestoneData.boards.length >0 && milestoneData.boards.some((board: BoardObject) => board.pid === this.boardPid)){
        return milestone;
      }else{
        return;
      }
    });
    return milestones.length;
  }
   */

  openBoardMembers() {
    this.$buefy.modal.open({
      component: BoardMembers,
      width: '480px',
      parent: this,
    });
  }

  exitFromRoom() {
    this.$events.fire('chat-closed');
    if(this.$store.getters.getLastSeenMessageId) {
      this.updateLastSeenMessage();
    }
    if(this.board) {
      PusherService.unsubscribeBoardSubscription(this.board.pid);
    }

  }

  updateLastSeenMessage() {
    this.$apollo.mutate({
      mutation: UpdateLastSeenMessageMutation,
      variables: {
        user_id: this.me.id,
        board_id: this.board.id,
        chat_message_id:  this.$store.getters.getLastSeenMessageId
      }
    })
      .then(() => {
        this.$store.commit('set_last_seen_message', null);
      })
  }


  @Watch('$store.state.member_added_to_board')
  memberJoined() {
    if(this.$store.state.member_added_to_board && (this.$store.state.member_added_to_board.pid == this.board.pid)){
      this.$buefy.toast.open({
        message: this.$store.state.member_added_to_board.message,
        position: 'is-bottom-right',
        type: 'is-black',
        duration: 3000
      });
    }
  }

  @Watch('$store.state.member_removed_from_board')
  memberLeaved() {
    if(this.$store.state.member_removed_from_board && (this.$store.state.member_removed_from_board.pid == this.board.pid)){
      this.$buefy.toast.open({
        message: this.$store.state.member_removed_from_board.message,
        position: 'is-bottom-right',
        type: 'is-black',
        duration: 3000
      });
    }
  }

  @Watch('$store.state.board')
  boardChanged() {
    if (this.board) {
      if (this.currentLoadedBoardId !== this.board.id) {
        if (this.currentLoadedBoardPid) {
          PusherService.unsubscribeBoardSubscription(this.currentLoadedBoardPid)
        }
        this.currentLoadedBoardId = this.board.id
        this.currentLoadedBoardPid = this.board.pid

        PusherService.boardSubscription(this.board.pid);
      }
      let index = this.board.members?.findIndex((member: any)=>{ return member.user.id === this.$store.state.me.id });
      if(index != -1){
        this.isMember = true;
      }else {
        this.isMember = false;
      }
      this.$events.fire('is-board-member', this.isMember);
      document.title = "Pembio | " + this.board.name;
    }
  }

  mounted() {
    this.$gtag.event('pmb_app_room_mounted');
    this.$events.listen('chat-loaded', (eventData => {
      this.chatMessageLoaded = eventData;
    }))
    this.$events.listen('objective-loaded', (eventData => {
      this.objectiveLoaded = eventData;
    }))
  }

  destroyed() {
    this.exitFromRoom();
    /**
     * This is important for the registration process.
     */
    if(this.$route.name !== 'board-create' && this.$route.name !== 'goal-create' && this.$route.name !== 'members-invite') {
      this.$store.commit('set_board', null);
      this.$store.commit('set_active_board_pid', null);
    }
    document.title = "Pembio";
  }


}
