















































































































import {Component, Mixins} from "vue-property-decorator";
import {
  CreatePersonalTemplateFromBoard,
  DeleteBoardHardMutation,
  DeleteBoardMutation,
  LeaveBoardMutation
} from "@/graphql/board";
import BoardMembers from "@/components/board/BoardMembers.vue";
import BoardPrivacy from "@/components/board/utilities/BoardPrivacy.vue";
import KeyboardShortcuts from "@/components/general/KeyboardShortcuts.vue";
import BoardInfoEdit from "@/components/board/utilities/BoardInfoEdit.vue";
import UserAvatar from "@/components/user/UserAvatar.vue";
import ShareUrl from "@/components/board/ShareUrl.vue";
import ConfirmDelete from "@/components/general/ConfirmDelete.vue";
import {Board as BoardObject, Role} from "@/typescript/types";
import {BoardMember} from "@/typescript/types";
import isUndefined from 'lodash/isUndefined';
import {ValidationErrorParserMixin} from "@/mixins/ValidationErrorParserMixin";
import {WorkspaceQuery} from "@/graphql/workspace";
import Template from "@/components/workspace/Template.vue";
import BoardChatIcon from "@/components/navigation/BoardChatIcon.vue";
import AddBookmark from "@/components/bookmarks/AddBookmark.vue";
import WorkableItem from "@/components/general/WorkableItem.vue";
import VerifyUser from "@/components/auth/VerifyUser.vue";
import Paywall from "@/components/subscription/Paywall.vue";
import ConvertToTemplate from "@/components/board/ConvertToTemplate.vue";


@Component({
  components: {
    WorkableItem,
    BoardChatIcon,
    Template,
    ConfirmDelete,
    ShareUrl,
    UserAvatar,
    BoardMembers,
    BoardPrivacy,
    KeyboardShortcuts,
    BoardInfoEdit,
    ConvertToTemplate
  },
})
export default class BoardSettingsDropdown extends Mixins(ValidationErrorParserMixin) {
  showMoreSettings: boolean = false;
  activeTab: string | null = 'bookmarks';
  muted: boolean = false;
  showMore: boolean = false;

  get me() {
    return this.$store.state.me;
  }
 
  
  get newUser() {
    let userCreationDate = new Date(this.me.created_at);
    let comparisonDate = new Date('2024-02-22');
    return userCreationDate > comparisonDate;
  }

  get onGuestAccount() {
    return this.$store.state.me.isFake;
  }

  get board() {
    return this.$store.state.board;
  }

  get workspace() {
    return this.$store.state.workspace;
  }

  get singleRoomWorkspace() {
    return this.workspace.boards.length === 1;
  }

  get isPremium() {
    if(this.$store.state.workspace.currentSubscription) {
      return true
    } else { return false }
  }

  get boardId() {
    return this.$store.state.board.id;
  }

  get adminMembers() {
    return this.board.members.filter((member: BoardMember) => {
      return member.role === Role.Owner;
    });
  }

  get isAdmin() {
    let currentUser = this.board.members.filter((member: BoardMember) => {
      if(member.user.id === this.me.id){
        return member;
      }
    });

    if(currentUser[0].role === Role.Owner) {
      return true;
    }

    return false;
  }

  get sharedResourcesCount() {
    // returning the number of shared files and bookmarks
    let totalCount = this.board.bookmarks.length + this.board.attachments.length;
    if (totalCount >= 1) {
      return totalCount;
    } else {
      return null;
    }
  }

  get planList() {
    return this.$store.state.plans;
  }

  get workspaceSubscription() {
    return this.$store.state.workspace.currentSubscription;
  }

  get currentPlan() {
    if(this.workspaceSubscription && !this.workspaceSubscription.hasAccess) {
      return this.planList[0];
    }else if(this.workspaceSubscription == null) {
      return this.planList[0];
    }
    return this.workspaceSubscription.plan;
  }

  get currentActivePlan() {
    if(this.workspace.currentSubscription !== null) {
      return this.workspace.currentSubscription.plan.title
    }
    else { return null }
  }

  checkIfUserBlockedFromWorkspace(userId: string) {
    let member = this.workspace.allMembers.filter((member: any) => {
      return member.user.id === userId;
    });


    if(member[0].access_workspace == 0) {
      return false;
    }else{
      return true;
    }
  }

  openKeyboardShortcuts() {
    this.$buefy.modal.open({
      component: KeyboardShortcuts,
      width: '480px',
      parent: this,
    });
  }

  openBoardInfoEdit() {
    this.$buefy.modal.open({
      component: BoardInfoEdit,
      width: '480px',
      parent: this,
    });
  }

  addBookmarks() {
    this.$buefy.modal.open({
      component: AddBookmark,
      width: '520px',
      props: {
        origin: 1,
        origin_id: this.board.id
      },
      parent: this,
    });
  }

  openBoardPrivacy() {
    this.$buefy.modal.open({
      component: BoardPrivacy,
      props: {
        open: true
      },
      width: '480px',
      parent: this,
    });
  }

  openBoardMembers() {
    if(this.onGuestAccount) {
      this.$buefy.modal.open({
        component: VerifyUser,
        props: {
          claimWs: (this.me.isFake) ?? false,
          clickedClaim: true
        },
        width: '500px',
        canCancel: !this.$store.state.currently_on_verification_code_step,
        parent: this,
      });
    } else {
      this.$buefy.modal.open({
        component: BoardMembers,
        width: '480px',
        parent: this,
      });
    }
  }

  openDeleteModal(type: string) {
    let deleteItemName = '';
    let deleteParagraph = '';
    if(this.$store.state.workspace.boards.length === 1)
    {
      this.$buefy.toast.open({
        message: "You can't delete the last room in the workspace.",
        position: "is-bottom-right",
        type: "is-warning",
      });
      return;
    }

    if(type == 'soft'){
      deleteItemName = 'Are you sure you want to archive this room?'
      deleteParagraph = 'We\'ll archive all content related to this. You can recover it later.'
    }else{
      deleteItemName = 'Are you sure you want to delete this room?'
      deleteParagraph = 'You\'ll lose all content related to this. We can\'t recover it once you confirm this action.'
    }

    this.$buefy.modal.open({
      component: ConfirmDelete,
      props: {
        deleteItemName: deleteItemName,
        deleteParagraph: deleteParagraph
      },
      events: {
        //@ts-ignore ToDo: fix type issue
        'confirmed': value => {
          if (value === true) {
            if(type == 'soft'){
              this.deleteBoard(this.$store.state.board.id);
            }else{
              this.deleteBoardHard(this.$store.state.board.id);
            }

          }
        }
      },
      width: '500px',
      parent: this,
    });
  }

  leaveBoard() {
    if (!this.isAbleToLeaveBoard()) {
      this.$buefy.toast.open({
        message: "You cannot leave the board as you are the only owner. Please assign another user as owner",
        position: "is-bottom-right",
        type: "is-warning",
      });
      return;
    }
    let id = this.board.id
    let haveExtraBoard = this.$store.state.workspace.boards.find((board: BoardObject) => {
      return board.id !== id
    });
    if (isUndefined(haveExtraBoard)) {
      this.$buefy.toast.open({
        message: "Create a new board to leave this board",
        position: "is-bottom-right",
        type: "is-warning",
      });
      return;
    }

    this.$store.dispatch('delete_board', id);
    this.$store.commit('set_active_board_pid', haveExtraBoard.pid);
    this.$router.push({name: 'workspace-layout'});
    this.$apollo.mutate({
      mutation: LeaveBoardMutation,
      variables: {
        id: id,
      },
      refetchQueries: [{query: WorkspaceQuery, variables: {pid: this.workspace.pid}}]
    }).catch((error) => {
      this.parseValidationError(error);
      this.$buefy.toast.open({
        message: this.fieldValidationMessage('leaveBoard'),
        position: "is-bottom-right",
        type: "is-warning",
      });

    })
  }

  deleteBoard(id: string) {

    //this.$store.dispatch('delete_board', id);

    this.$apollo
      .mutate({
        mutation: DeleteBoardMutation,
        variables: {
          id: id,
        },
        refetchQueries: [{query: WorkspaceQuery, variables: {id: this.$store.state.workspace.id}}]
      })
      .catch((_error) => {
      
      })
      .finally(() => {
        this.$router.push({
          name: 'workspace-layout'
        });
        this.$buefy.toast.open({
          message: 'Archived successfully',
          position: "is-bottom-right",
          type: "is-black",
        });
      })
    ;
  }

  deleteBoardHard(id: string) {

    this.$apollo
      .mutate({
        mutation: DeleteBoardHardMutation,
        variables: {
          id: id,
        },
        refetchQueries: [{query: WorkspaceQuery, variables: {id: this.$store.state.workspace.id}}]
      })
      .catch((_error) => {
        
      })
      .finally(() => {
        this.$router.push({
          name: 'workspace-layout', params: { workspace_pid: this.$store.state.workspace.pid }
        }).then(() => {
          location.reload();
        });
        this.$buefy.toast.open({
          message: 'Deleted successfully',
          position: "is-bottom-right",
          type: "is-black",
        });
      })
    ;
  }

  isAbleToLeaveBoard() {
    return this.adminMembers.length == 1 && this.adminMembers[0].user.id === this.me.id ? false : true;
  }

  confirmCreateTemplate() {
    this.$buefy.modal.open({
      component: ConvertToTemplate,
      events: {
        //@ts-ignore ToDo: fix type issue
        'confirmed': value => {
          
          if(value) {
            this.createTemplate();
          }
        }
      },
      parent: this,
      width: 500,
    });
  }

  createTemplate() {
    this.$apollo.mutate({
      mutation: CreatePersonalTemplateFromBoard,
      variables: {
        board_id: this.board.id
      }
    }).then((_res: any) => {
  
      this.$buefy.toast.open({
        message: 'Template created successfully',
        position: "is-bottom-right",
        type: "is-black",
      });
    })
  }

  openPaywall() {
    this.$buefy.modal.open({
      component: Paywall,
      // @ts-ignore
      customClass: 'split-modal',
      parent: this,
    });


  }

}
