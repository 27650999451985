

























































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {NorthStar} from "@/typescript/types";
import VueDraggableResizable from 'vue-draggable-resizable';
import {addMonths, differenceInCalendarMonths, getDaysInMonth, setDate, isPast} from "date-fns";
import NorthStarService from "@/NorthStarService";
import NorthStarData from "@/components/northstars/NorthStarData.vue";
import { utcToZonedTime } from 'date-fns-tz';
import { isWithinInterval } from 'date-fns';
import ConfirmDelete from "@/components/general/ConfirmDelete.vue";


@Component({
  components: {VueDraggableResizable},
})
export default class NorthstarGanttSwimLane extends Vue {
  service: NorthStarService | null = null;
  clipBoardId: string | null = null;

  activeLaneWidth: number | string | null = 180;
  activeLaneX: any | null = 180;

  hasNoDueDate: boolean = false;

  newlyCreated: boolean = false;
  activityCompleted: boolean = false;

  cellGranularityOffset: any | null = null;

  nsDueDate: any | null = null;
  nsStartDate: any | null = null;

  editNameActive: boolean = false;
  nsName: string | null = null;
  isSelected: boolean = false;
  leftEdgeisOffScreen: boolean = false;
  rightEdgeisOffScreen: boolean = false;
  isDragging: boolean = false;

  @Prop({default: undefined, required: false})
  northStar!: NorthStar;

  @Prop({ required: true, default: '186' })
  gridScale!: any | null;

  @Prop({ required: false, default: '6' })
  gridScaleGranularity!: any | null;

  @Prop({ required: true })
  intervalStart!: any | null;

  @Prop({ required: true })
  selected!: boolean | null;

  @Prop({ required: true })
  gridCells!: any | null;

  @Prop({ required: false })
  beingAdded!: boolean | null;

  @Prop({ required: false })
  unscheduled!: boolean | null;

  @Prop({ default: () => new Date(), required: false })
  startDate!: Date;

  @Prop({ default: () => new Date(), required: false })
  dueDate!: Date;

  @Prop({ required: false, default: false })
  isLastCreated!: boolean;

  parse(date:any) {
    return new Date(date);
  }

  
  jumpToMonth(month: Date) {
    if(!month) {
      let currentMonth = new Date();
      this.$emit('period-clicked', currentMonth);
    } else {
      this.$emit('period-clicked', month);
    } 
  }

  get isPast() {
    if(this.northStar.due_date) {
      return isPast(new Date(this.northStar.due_date))
    } else {
      return false;
    }
  }

  get onboardingStep() {
    return this.$store.state.user_onboarding_number;
  }

  get dndOnboarding() {
    return this.$store.state.dnd_onboarding;
  }

  get hasStartDate() {
    return !!this.nsStartDate;
  }

  get isNorthStarWithinRange() {
    if (!this.northStar.start_date || !this.northStar.due_date) {
      return false;
    }

    const formattedStartDate = utcToZonedTime(this.startDate, 'Europe/Paris');
    const formattedDueDate = utcToZonedTime(this.dueDate, 'Europe/Paris');
    const northStarStartDate = utcToZonedTime(new Date(this.northStar.start_date), 'Europe/Paris');
    const northStarDueDate = utcToZonedTime(new Date(this.northStar.due_date), 'Europe/Paris');

    // Check if the start date or due date is within the range
    const isStartDateInRange = isWithinInterval(northStarStartDate, {
      start: formattedStartDate,
      end: formattedDueDate,
    });

    const isDueDateInRange = isWithinInterval(northStarDueDate, {
      start: formattedStartDate,
      end: formattedDueDate,
    });

    // Check if the start date is before the range and the due date is after the range
    const isSpanningEntireRange =
    northStarStartDate <= formattedStartDate && northStarDueDate >= formattedDueDate;

    // Return true if the start date or due date is within range or spans entire range
    return isStartDateInRange || isDueDateInRange || isSpanningEntireRange;
  }

  get backgroundColor() {
    return this.isNorthStarWithinRange ? this.northStar.color : '#6C6C6C';
  }

  get textColor() {
    return this.isNorthStarWithinRange ? this.northStar.color : '#676767'; 
  }

  

  copyNorthstar() {
    if(this.clipBoardId === this.northStar.id) {
      this.service?.copyNorthStar(this.northStar.id, this.$store.state.board.id!, this.$store.state.workspace.id).then((res: any) => {
        if(res.data.copyNorthStar && res.data.copyNorthStar.boards.length > 0) {
          this.$store.commit('set_newly_created_northstar_count', (this.$store.state.newlyCreatedNorthstarsCountInWorkspace == null) ? 1 : this.$store.state.newlyCreatedNorthstarsCountInWorkspace + 1)
          this.$events.fire('northstars-updated-my-northstar', res.data.copyNorthStar);
        }
      })
    }
  }

  setStartOffset() {
    if(this.northStar.start_date) {
      const cellCountFromStart = differenceInCalendarMonths(new Date(this.northStar.start_date), new Date(this.intervalStart)) * this.gridScale;
      const startDate = new Date(this.northStar.start_date);
      const dayOfCurrentDate = startDate.getDate();

      // Calculate the pixel offset within a cell, skipping the offset for the first day of the month
      const cellGranularityOffset = dayOfCurrentDate === 1 ? 0 : dayOfCurrentDate * this.gridScaleGranularity;

      this.cellGranularityOffset = cellGranularityOffset;
      this.activeLaneX = cellCountFromStart + cellGranularityOffset;
    } else if(!this.northStar.start_date && this.northStar.due_date){
      const cellPlacement = differenceInCalendarMonths(new Date(this.northStar.due_date), new Date(this.intervalStart)) * this.gridScale;
      this.activeLaneX = cellPlacement;
    } else {
      const currentDate = new Date();
      const currentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      const cellCountFromStart = differenceInCalendarMonths(currentMonth, new Date(this.intervalStart)) * this.gridScale;
      this.activeLaneX = cellCountFromStart;
    }
  }

  setLaneWidth() {
    if(!this.northStar.due_date) {
      this.activeLaneWidth = this.gridScale*0.5;
      return
    }
    if(!this.northStar.start_date) {
      this.activeLaneWidth = this.gridScale*1;
      return
    }
    const diffMonths = differenceInCalendarMonths(new Date(this.nsDueDate), new Date(this.nsStartDate));
    const monthsOffsetInPixels = diffMonths * this.gridScale;
    const dayOfLastMonth = this.nsDueDate.getDate();
    const maxDaysInMonth = getDaysInMonth(this.nsDueDate);

    let dayGranularityConvertedToPixels = 0;

    if (dayOfLastMonth >= maxDaysInMonth) {
      dayGranularityConvertedToPixels = this.gridScale;
    } else {
      dayGranularityConvertedToPixels = dayOfLastMonth * this.gridScaleGranularity;
    }

    // Calculate the total width and adjust for cell granularity offset
    this.activeLaneWidth = Math.abs(monthsOffsetInPixels + dayGranularityConvertedToPixels - this.cellGranularityOffset);
  }

  calculateStartMonth() {
    const offsetMonthCount = Math.trunc(this.activeLaneX / this.gridScale);
    const currentMonth = addMonths(new Date(this.intervalStart), offsetMonthCount);
    const monthRelativePixelCount = this.activeLaneX - (this.gridScale * offsetMonthCount);
    const dayRepresentation = Math.max(1, Math.trunc(monthRelativePixelCount / this.gridScaleGranularity));
    const maxDueDateOfMonth = getDaysInMonth(currentMonth);
    const realMaxDate = Math.min(dayRepresentation, maxDueDateOfMonth);

    const realStartDate = setDate(currentMonth, realMaxDate);
    this.nsStartDate = realStartDate;
    
    this.calculateEndDate();
  }

  calculateEndDate() {
    let currentCell = Math.ceil(Math.max(0, (this.activeLaneX + this.activeLaneWidth) / (this.gridScale) - 1));
    let month = this.gridCells[currentCell];

    // calculate the end position of the item
    let endCellPosition = this.activeLaneWidth + this.activeLaneX;

    let concentratePixelsToCurrentCell = endCellPosition - (currentCell*this.gridScale);

    if(concentratePixelsToCurrentCell === 0) {
      concentratePixelsToCurrentCell = this.gridScale;
    }

    let dayRepresentation = Math.trunc(concentratePixelsToCurrentCell / this.gridScaleGranularity);

    let maxDueDateOfMonth = getDaysInMonth(new Date(month));

    let realMaxDate = 0;

    if (dayRepresentation > maxDueDateOfMonth) {
      realMaxDate = maxDueDateOfMonth;
    } else {
      realMaxDate = dayRepresentation;
    }

    let finalDate = setDate(new Date(month), realMaxDate);
    this.nsDueDate = finalDate;
    this.updateNorthStar();
  }

  onResize(handle: any, x: any, y: any, width: any, height: any) {
    console.log(handle, x, y, width, height);
    this.activeLaneWidth = y*1;
    this.calculateEndDate();
  }

  select(month: Date) {
    if (!this.isSelected) {
      this.isSelected = true;
      this.$emit('selected', true);
      this.addClickListener();
    }
    if(!this.isDragging) { 
      const formattedDate = utcToZonedTime(month, 'Europe/Paris');
      this.jumpToMonth(formattedDate)
    }
  }

  onDragStop(data: any) {
    let position = data;
    this.activeLaneX = position;
    this.calculateStartMonth();
    this.$gtag.event('northstar_initiatied_with_drag');
    setTimeout(() => this.isDragging = false, 190);

    // // Determine the date at the drop location
    // const offsetMonthCount = Math.trunc(this.activeLaneX / this.gridScale);
    // const currentMonth = addMonths(new Date(this.intervalStart), offsetMonthCount);
    // const monthRelativePixelCount = this.activeLaneX - (this.gridScale * offsetMonthCount);
    // const dayRepresentation = Math.max(1, Math.trunc(monthRelativePixelCount / this.gridScaleGranularity));
    // const maxDueDateOfMonth = getDaysInMonth(currentMonth);
    // const realMaxDate = Math.min(dayRepresentation, maxDueDateOfMonth);

    // const dropDate = setDate(currentMonth, realMaxDate);

    // // Call the select function with the drop date
    // this.select(dropDate);
  }

  // onDragStart() {
  //   this.$store.commit('set_user_onboarding_step', null);
  // }

  onDragging() {
    setTimeout(() => this.isDragging = true, 150);
  }

  get keymap() {
    return {
      // 'esc+ctrl' is OK.  - OBSERVE: don't add parenthesis at the end of the function triggered.. This messes things up.
      'meta+c': this.copyToClipBoard,
      'meta+v': this.copyNorthstar,
      
      'backspace': this.openDeleteModal,
    }
  }

  setupIntersectionObserver() {
    const gridBody = document.querySelector('.pmb_gantt_grid_body');
    const leftEdge = this.$el.querySelector('.left-edge-marker');
    const rightEdge = this.$el.querySelector('.grid_item_card');

    if (gridBody) {
      if (leftEdge) {
        const updateLeftEdgeOffScreenStatus = (entries: IntersectionObserverEntry[]) => {
          entries.forEach(entry => {
            this.leftEdgeisOffScreen = !entry.isIntersecting;
          });
        };

        const leftEdgeObserver = new IntersectionObserver(updateLeftEdgeOffScreenStatus, {
          root: gridBody,
          threshold: 0,
        });

        leftEdgeObserver.observe(leftEdge);
      }

      if (rightEdge) {
        const updateRightEdgeOffScreenStatus = (entries: IntersectionObserverEntry[]) => {
          entries.forEach(entry => {
            this.rightEdgeisOffScreen = !entry.isIntersecting;
          });
        };

        const rightEdgeObserver = new IntersectionObserver(updateRightEdgeOffScreenStatus, {
          root: gridBody,
          rootMargin: '0px 0px 0px -1px',  // top, right, bottom, left
          threshold: 0,
        });

        rightEdgeObserver.observe(rightEdge);
      }
    }
  }



  


  removeClickListener() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  addClickListener() {
    document.addEventListener('click', this.handleClickOutside);
  }

  handleClickOutside(event: MouseEvent) {
    const inlineBox = this.$el.querySelector('.grid_item_card');

    if (inlineBox && !inlineBox.contains(event.target as Node)) {
      this.isSelected = false;
      this.$emit('selected', false);  // Notify parent component of the change
      this.removeClickListener();
    }
  }

  copyToClipBoard() {
    if(this.selected) {
      this.clipBoardId = this.northStar.id;
    }
  }

  dblClick() {
    this.openNorthStarAsModal(this.northStar.id);
  }

  openDeleteModal() {
    if(this.selected) {
      this.$buefy.modal.open({
        component: ConfirmDelete,
        props: {
          deleteItemName: 'Are you sure you want to delete ',
          goalName: this.northStar.name + "?"
        },
        events: {
        //@ts-ignore ToDo: fix type issue
          'confirmed': value => {
            this.delete()
          }
        },
        width: '500px',
        parent: this,
      });
    }
  }

  delete() {
    if(this.selected) {
      this.$emit('delete-item', true);
    }
  }

  editName() {
    this.editNameActive = !this.editNameActive;
    this.$nextTick(function () {
      //@ts-ignore ToDo: fix type issue
      this.$refs.nsInput.focus();
    });
  }

  openNorthStarAsModal(id: string) {
    this.$buefy.modal.open({
      component: NorthStarData,
      props: {
        onmodal: true,
        editable: true,
        showMilestones: true,
        board: (this.$store.state.board) ? this.$store.state.board : this.$props.board,
        id: id
      },
      width: '780px',
      animation: 'none',
      customClass: 'northstar-detailed is-paddingless',
      // @ts-ignore
      parent: this,
    });
  }

  updateNorthstarName(): void {
    if(this.northStar) {
      this.service?.updateNorthStarName(this.northStar.id, this.nsName).then((res: any) => {
        this.nsName = null;
        this.editNameActive = false;
        this.$events.fire('northstars-updated-my-northstar', res);
        this.$emit('added', true);
      }).finally(() => {
        // this.$store.commit('set_user_onboarding_step', null);
      })
    }
    
  }

  updateNorthStar(): void {
    if(this.northStar) {
      this.service?.updateNorthStarDates(this.northStar.id, this.nsStartDate, this.nsDueDate).then((res: any) => {
        this.$events.fire('northstars-updated-my-northstar', res);
        // this.$events.fire('reorder-list');
        if (this.unscheduled && this.nsStartDate && this.nsDueDate) {
          this.$buefy.toast.open({
            message: 'Goal scheduled!',
            position: 'is-bottom-right',
            type: 'is-black',
          });
        }
      }).finally(() => {
        // this.$store.commit('set_user_onboarding_step', null);
       
      })
    }
   
  }

  @Watch('northStar.start_date')
  startDateChanged(newDate: Date){
    this.nsStartDate = new Date(newDate);
    this.setStartOffset();
    this.setLaneWidth();
  }

  @Watch('northStar.due_date')
  dueDateChanged(newDate: Date){
    this.nsDueDate = new Date(newDate);
    this.setLaneWidth();
  }

  beforeDestroy() {
    this.removeClickListener();
  }

  mounted() {
    this.service = new NorthStarService(this.$apollo);
    if(this.northStar.start_date) {
      this.nsStartDate = new Date(this.northStar.start_date);
    } else {
      this.nsStartDate = null;
    }
    if(this.northStar.due_date) {
      this.nsDueDate = new Date(this.northStar.due_date);
    } else {
      this.nsDueDate = null;
    }

    this.setupIntersectionObserver();
    this.setStartOffset();
    this.setLaneWidth();

    this.$events.listen('new-northstar-id-created', (id => {
      if(id === this.northStar.id) {
        this.newlyCreated = true;
        setTimeout(() => this.newlyCreated = false, 2000);
      }
    }))

    this.$events.listen('completed-activity-id', (id => {
      if(id === this.northStar.id) {
        this.activityCompleted = true;
        setTimeout(() => this.activityCompleted = false, 2000);
      }
    }))

    this.$events.listen('ns_added_from_onboarding', (_) => {
      this.newlyCreated = true;
            
    })

    
  }

  @Watch('selected')
  onSelectedChanged(newValue: boolean) {
    this.isSelected = newValue;
    if (newValue) {
      this.addClickListener();
    } else {
      this.removeClickListener();
    }
  }

}
