



























































































































































import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import { UpdateObjective} from '@/graphql/objective';
import SmartDateTime from "@/components/general/SmartDateTime.vue";
import QuickDateSelector from "@/components/board/objective/QuickDateSelector.vue";

import {NorthStar} from "@/typescript/types";
import NorthStarService from "@/NorthStarService";
import WorkableItem from "@/components/general/WorkableItem.vue";
import {SingleNorthStar} from "@/graphql/NorthStar";


import VerifyUser from '@/components/auth/VerifyUser.vue';
import RoomTutorial from '@/components/auth/demo/RoomTutorial.vue';


@Component({
  components: {
    WorkableItem,
    QuickDateSelector, 
    SmartDateTime,
  },
})
export default class CreateNorthstarInline extends Vue {



 
  selectedSuggestions: Array<string> = [];


  assignedPriority: string = 'normal';

  submitted: boolean = false;

  startDate: Date | null = null;
  dueDate: Date | null = null;
  endDate: string | null = null;
  discardNaturalLanguageDate: boolean = false;
  showSuggestions: boolean = false;

 

  name: string = '';

  wait: boolean = false;
  error: boolean = false;

  service: NorthStarService | null = null;

  northstarData: NorthStar | null = null;
  selectedColor: string | null = null;
  
  selectedNorthStar: NorthStar | null = null;
  createNorthStarInput: string | null = null;

  colors: Array<string> = [
    '#773f75',
    '#bd5fb4',
    '#374ea9',
    '#776015',
    '#7b7343',
    '#316863',
    '#2e825f',
    '#654C4F',
    '#5B507A',
    '#546356',
    '#1F4730',
    '#642B2C',
    '#2E294E',
    '#820263',
    '#64024C',
  ];


  @Prop({default: null, required: false})
  startDateFromSprint!: Date;

  @Prop({default: null, required: false})
  dueDateFromSprint!: Date;

  @Prop({default: undefined, required: false})
  northStar!: NorthStar;

  @Prop({default: true, required: false})
  showDateIndicator: boolean | null | undefined;

  @Prop({default: true, required: false})
  focusOnMount: boolean | null | undefined;

  @Prop({required: false, default: true})
  showChronoDate!: boolean;

  @Prop({required: false, default: false})
  inModal!: boolean;

  @Prop({required: false, default: null })
  color!: string | null;

  @Prop({required: false, default: null})
  boardId!: string

  @Prop({required: false, default: null})
  isCreateGoalOpen!: string


  


  mounted() {
    this.$gtag.event('pmb_app_guided_create_objective_mounted');
    this.setStartDate()

   
   
    if(this.$props.northStar) {
     
      if(this.$props.northStar.objective_suggestions && this.$props.northStar.objective_suggestions.length > 0) {
        this.northstarData = this.$props.northStar;
      }
      // else{
      //   this.northstarWithSuggestions();
      //
      // }
    }

    

    if(this.focusOnMount) {
      this.$nextTick(function(){
        if(this.$refs.oInput) {
          //@ts-ignore ToDo: fix type issue
          this.$refs.oInput.focus();
        }
      });
    }


    if(this.dueDateFromSprint) {
      this.dueDate = this.dueDateFromSprint;
    }

    if(this.startDateFromSprint) {
      this.startDate = this.startDateFromSprint;
    }
    this.service = new NorthStarService(this.$apollo);
  }

 
  

  // get onboardingStep() {
  //   return this.$store.state.user_onboarding_number;
  // }

  get accountAge() {
    const userCreationDate = new Date(this.me.created_at); 
    const currentDate = new Date(); 
    const differenceInTime = currentDate.getTime() - userCreationDate.getTime(); 
    const differenceInDays = differenceInTime / (1000 * 3600 * 24); 
    return differenceInDays ;
  }

  get colorFromNs() {
    if(this.$props.northStar) {
      return this.$props.northStar.color;
    } else {
      return 'grey';
    }
  }

  

  get finalColor() {
    if(this.selectedColor) {
      return this.selectedColor;
    } else if(this.$props.origin == 'board' && this.suggestColorFromNsCount) {
      return this.suggestColorFromNsCount;
    } else {
      return null;
    }
  }

  get board() {
    return this.$store.state.board;
  }

  get me() {
    return this.$store.state.me;
  }

  get isDueDateBeforeStartDate() {
    if (!this.startDate || !this.dueDate) {
      return false;
    }
    return this.dueDate < this.startDate;
  }

  get origin() { return this.$store.state.board ? 'board' : 'workspace'}

  get workspace() {
    return this.$store.state.workspace;
  }

  get activitesCreated() {
    return this.workspace.totalObjectivesCount
  }

  get isPremium() {
    if(this.$store.state.workspace.currentSubscription) {
      return true
    } else { return false }
  }

  get currentlyOnboarding() {
    return !!(this.$store.state.currently_onboarding || this.$store.state.currently_onboarding_in_room);
  }

  get onGuestAccount() {
    return this.$store.state.me.isFake;
  }

  get suggestColorFromNsCount() {
    let count = this.$store.state.board.northStars.length;
    if(count === 0) {
      return '#773f75';
    } else if(count === 1) {
      return '#4a677d';
    } else if(count === 2) {
      return '#7b7343';
    } else {
      return null;
    }
  }

 

  createOrAddNorthStar(): void {
    this.$gtag.event('pmb_create_ns');
    
    this.submitted = true;
    if(!this.selectedColor) {
      const randomIndex = Math.floor(Math.random() * this.colors.length);
      this.selectedColor = this.colors[randomIndex];
    }
    if (this.selectedNorthStar) {
      this.addNorthStar(this.selectedNorthStar)
      return;
    }
    
    if (this.origin === 'board') {
      this.service?.createNorthStar(this.workspace, this.board, this.createNorthStarInput!, this.$props.startDate, this.$props.startDate , this.finalColor).then((result: any) => {
        this.$emit('completed', true)
        this.$store.commit('set_newly_created_northstar_count', (this.$store.state.newlyCreatedNorthstarsCountInWorkspace == null) ? 1 : this.$store.state.newlyCreatedNorthstarsCountInWorkspace + 1)
        this.$store.commit('set_newly_created_northstar_in_board', result.data.createNorthStar.id);
        this.$gtag.event('pembio_create_north_star');
        this.reset();
        this.$events.fire('new_northstar_created', result.data.createNorthStar)
       
        if(this.$props.inModal) {
          //@ts-ignore
          this.$parent.close();
        }

      })
    } else if(this.origin == 'workspace') {
      this.service?.createNorthStarWithoutBoard(this.workspace, this.createNorthStarInput!, null, this.$props.startDate, this.finalColor).then((result: any) => {
        this.$emit('completed', true)
        this.$store.commit('set_newly_created_northstar_count', (this.$store.state.newlyCreatedNorthstarsCountInWorkspace == null) ? 1 : this.$store.state.newlyCreatedNorthstarsCountInWorkspace + 1)
        this.$events.fire('new_northstar_created', result.data.createNorthStar)
        this.$gtag.event('pembio_create_north_star');
        if(this.$props.inModal) {
          //@ts-ignore
          this.$parent.close();
        }
      })
    }
    this.createNorthStarInput = null;
    this.$emit('northstar-added', true);
    this.$gtag.event('goal_created_inline');
    this.$gtag.event('northstar_initiated_with_schedule_button');
    this.$store.commit('set_add_goal_open', false);
  }

  addNorthStar(northStar: NorthStar): void {
    this.selectedNorthStar = null;
    let northStarBoards = [...northStar.boards,this.board];
    this.service?.addBoardToNorthStar(northStarBoards, northStar).then(() => {
      this.$emit('completed', true)
      if(this.$props.inModal) {
        //@ts-ignore
        this.$parent.close();
      }
    })
  }


 

  focusInput() {
    this.$nextTick(function(){
      if(this.$refs.oInput) {
        //@ts-ignore ToDo: fix type issue
        this.$refs.oInput.focus();

        
      }
    });
  }

  ifAlreadySelected(objectiveId: string) {
    return this.selectedSuggestions.includes(objectiveId);
  }

  northstarWithSuggestions() {
    this.$apollo.query({
      query: SingleNorthStar,
      variables: {
        goal_id: this.$props.northStar.id
      }
    }).then((result: any) => {
      if(result) {
        this.northstarData = result.data.getNorthStar;
      }
    })
  }

  // removeClickListener() {
  //   console.log('listener removed')
  //   document.removeEventListener('click', this.handleClickOutside);
  //   this.$store.commit('set_add_goal_open', false);
  // }


  // addClickListener() {
  //   console.log('listener added')
  //   document.addEventListener('click', this.handleClickOutside);
  // }
 
  // handleClickOutside(event: MouseEvent) {
  //   const inlineBox = this.$el.querySelector('.create_goal_inline_box');

  //   if (inlineBox && !inlineBox.contains(event.target as Node)) {
  //     console.log('clicket outside,listener removed')
  //     this.removeClickListener()
  //   }
  // }

  reset() {
    this.createNorthStarInput = null;
    this.selectedColor = null;
    this.submitted = false;
  }



  openDatePicker(type: string) {
    let date;
    if(type === 'due' && this.dueDate) {
      date = this.dueDate;
      date.setHours(17, 0, 0, 0);
    } else if(type === 'start' && this.startDate) {
      date = this.startDate;
      date.setHours(9, 0, 0, 0);
    } else {
      date = new Date();
      date.setHours(17, 0, 0, 0);
    }

    this.$buefy.modal.open({
      component: SmartDateTime,
      width: '280px',
      // @ts-ignore
      customClass: 'is-paddingless',
      parent: this,
      props: { 'remove-due-date': true, 'set-date': date},
      events: {
        //@ts-ignore ToDo: fix type issue
        'date': value => {
          if(type === 'due') {
            if(value) {
              this.dueDate = value;
            } else {
              this.dueDate = null;
            }
          } else if(type === 'start'){
            if(value) {
              this.startDate = value;
            } else {
              this.startDate = null;
            }
          }
          this.focusInput();
        }
      },
    });
  }

 

  setStartDate(): void {
    /**
     * Set tomorrow as due datetime
     * */
    let today = new Date();
    if (this.startDateFromSprint) {
      this.startDate = this.startDateFromSprint;
    } else {
      this.startDate = today;
    }
  }

  hasError() {
    this.error = true;
    setTimeout(() => { this.error = false }, 1000);
  }

  copyObjective(objective_id: string, northstar_id: string) {
    this.$gtag.event('pembio_create_objective_from_suggestion');
    let boardId;
    if(this.$store.state.board) {
      boardId = this.$store.state.board.id;
    } else if(this.northstarData && this.northstarData.boards && this.northstarData.boards.length >= 1) {
      boardId = this.northstarData.boards[0].id
    } else {
      boardId = undefined;
    }
    if(boardId) {
      this.selectedSuggestions.push(objective_id);
      this.service?.copyObjectiveForNorthStar(northstar_id, objective_id, boardId).then((res: any) => {
        this.$events.fire('objective-created-from-northstar', {objective: res.data.copyObjectiveForNorthstar, northStarId: northstar_id });
        
        this.setDateToCopiedObjective(res.data.copyObjectiveForNorthstar.id);
        if(this.$store.state.currently_onboarding_in_room) {
          this.$store.commit('set_onboarding_activities_added', true);
        }
      });
    }
  }

  setDateToCopiedObjective(id: string) {
    this.$apollo.mutate({
      mutation: UpdateObjective,
      variables: {
        id: id,
        start_date: this.startDate,
        date: this.dueDate
      }
    })
  }

 
 

 

  openClaimAccount() {
    this.$gtag.event('pmb_user_claim_account_from_page_blocker');
    this.$buefy.modal.open({
      component: VerifyUser,
      props: {
        claimWs: (this.$store.state.me.isFake) ?? false,
        clickedClaim: true,
        canBypass: false,
        activateFeedback: true,
      },
      width: '500px',
      canCancel: false,
      parent: this,
    });
  }

  openComplete() {
    this.$buefy.modal.open({
      component: RoomTutorial,
      width: '800px',
      parent: this,
      customClass: 'room_onboarding_modal',
      canCancel: false,
    });
  }

  openVerifyUser() {
    this.$nextTick(function () {
      this.$buefy.modal.open({
        component: VerifyUser,
        props: {
          claimWs: (this.$store.state.me.isFake) ?? false,
          clickedClaim: true,
          signUpWithEmail: true
        },
        width: '500px',
        canCancel: false,
        parent: this,
      });
    });
  }

  @Watch('isCreateGoalOpen')
  onAddAGoalOpenChange(newVal: boolean) {
    if (newVal) {
      this.$nextTick(() => {
        if (this.$refs.oInput) {
          //@ts-ignore ToDo: fix type issue
          this.$refs.oInput.focus();
          
        }
      });
    }
  }
  
  @Watch('startDateFromSprint')
  onStartDateFromSprintChange() {
    this.setStartDate();
  }

}

